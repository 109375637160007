<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col sm="12" md="8">
                <p><b-link :to="{ name: 'MarCases' }">all cases</b-link></p>
                <h2>
                  <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'case' }}">Edit</b-button>
                  {{item.name}}
                </h2>
                <delete-standard
                  v-if="permission.delete && step.delete"
                  :id="item.id"
                  model="case"
                ></delete-standard>
                <h3 v-if="item.description">Description</h3>
                <div v-if="item.description" v-html="item.description"></div>
                <h3 v-if="item.regulatory">Regulatory santions</h3>
                <div v-if="item.regulatory" v-html="item.regulatory"></div>
                <h3 v-if="item.criminal">Criminal santions</h3>
                <div v-if="item.criminal" v-html="item.criminal"></div>
                <h3 v-if="item.tailoring">Risk tailoring</h3>
                <div v-if="item.tailoring" v-html="item.tailoring"></div>
              </b-col>
              <b-col>
                <h4>Workflow</h4>
                <div>status: <strong>{{step.name}}</strong></div>
                <div>
                  <span v-if="!savingStep && permission.edit">
                    <b-button class="mr-4" v-if="step.downStep && permissionWorkflowDown" variant="outline-primary" size="sm" @click="stepUpdate(step.down)">move to: {{step.downStep.name}}</b-button>
                    <b-button v-if="step.upStep && permissionWorkflowUp" variant="outline-primary" size="sm" @click="stepUpdate(step.up)">move to: {{step.upStep.name}}</b-button>
                  </span>
                  <span v-if="savingStep">
                    <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
                  </span>
                </div>
                <h4>Loss (fines)</h4>
                <div>
                  {{Number.parseInt(item.loss).toLocaleString()}} USD
                </div>
                <h4>Impact</h4>
                <div>
                  {{item.coverage.name}}
                </div>
                <h4 v-if="item.start || item.end">Period</h4>
                <div>
                  <span v-if="item.start">{{moment(item.start).format('YYYY-MM-DD')}} -</span><span v-if="item.end"> {{moment(item.end).format('YYYY-MM-DD')}}</span>
                </div>
                <h4>Jurisdictions</h4>
                <div v-for="row in item.jurisdictions" :key="'jurisdiction' + row.country">
                  {{row.country}}
                </div>
                <h4>Control risks (level 2)</h4>
                <div v-for="row in item.controlrisks" :key="'controlrisk' + row.id">
                  {{row.name}}
                </div>
                <h4>MAR risks (level 3)</h4>
                <div v-for="row in item.risks" :key="'risk' + row.id">
                  <b-link :to="{ name: 'MarRisk', params: { id: row.id }}">{{row.name}}</b-link>
                </div>
                <h4>Asset classes</h4>
                <div v-for="row in item.assetclasses" :key="'assetclass' + row.id">
                  {{row.name}}
                </div>
                <h4>Product types</h4>
                <div v-for="row in item.producttypes" :key="'producttype' + row.id">
                  {{row.name}}
                </div>
                <div>{{item.products}}</div>
                <h4>Persons</h4>
                <div v-for="row in item.persons" :key="'person' + row.id">
                  {{row.name}} - {{row.persontype.name}}
                </div>
                <h4>News</h4>
                <div v-for="row in item.publications" :key="'newsitem' + row.id">
                  <router-link :to="{ name: 'NewsItem', params: { id: row.id }}">{{row.name}}</router-link>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import workflow from '@/workflows/case'
import DeleteStandard from '@/components/DeleteStandard'

export default {
  name: 'Case',
  components: {
    DeleteStandard
  },
  computed: {
    cases: {
      get () {
        return this.$store.state.cases
      },
      set (payload) {
        this.$store.commit('setCases', payload)
      }
    },
    permissionWorkflowDown: function () {
      let result = false
      if (this.step.id > 7 && ac.can(this.user.acgroups).readAny('caseWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id <= 7 && ac.can(this.user.acgroups).createAny('case').granted) {
        result = true
      }
      return result
    },
    permissionWorkflowUp: function () {
      let result = false
      if (this.step.id >= 7 && ac.can(this.user.acgroups).readAny('caseWorkflowApprove').granted) {
        result = true
      }
      if (this.step.id < 7 && ac.can(this.user.acgroups).createAny('case').granted) {
        result = true
      }
      return result
    },
    step: function () {
      const step = workflow.find(x => x.id === this.item.status_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'case', action: 'open case', model: 'case', model_id: this.$route.params.id })
    this.permission.read = ac.can(this.user.acgroups).readAny('case').granted
    this.permission.edit = ac.can(this.user.acgroups).updateAny('case').granted
    this.permission.delete = ac.can(this.user.acgroups).deleteAny('case').granted
    this.load()
  },
  data () {
    return {
      loading: true,
      item: null,
      moment: moment,
      permission: {
        delete: false,
        edit: false,
        read: false
      },
      savingStep: false
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        const apiName = 'cosmos'
        const path = `/case/${this.$route.params.id}`
        this.item = await this.$Amplify.API.get(apiName, path)
        document.title = this.item.name
        this.loading = false
      } catch (e) {
        this.$logger.warn(e)
      }
    },
    stepUpdate: async function (id) {
      this.savingStep = true
      try {
        const params = {
          body: {
            status_id: Number.parseInt(id)
          }
        }
        await this.$Amplify.API.put('cosmos', `/standard/case/${this.item.id}`, params)
        this.item.status_id = id
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.savingStep = false
    }
  }
}
</script>

<style scoped>
h3 {
  margin-top: 1.5em;
}
h4 {
  margin-top: 1.5em;
}
</style>
